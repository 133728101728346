import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Plastic Cone Sleeves in Epicore MSR Slabs' },
    { img: data.i2, caption: 'Plastic Cone Sleeves' },
    { img: data.i3, caption: 'Roof Drains Prior to Pouring Concrete' },
    { img: data.i4, caption: 'Blockout & Hilti Sleeve' },
    { img: data.i5, caption: 'Hilti Sleeves in Epicore MSR Deck' },
    { img: data.i6, caption: 'Hilti Sleeves in Epicore MSR Deck' },
    { img: data.i7, caption: 'Core Drilling' },
    { img: data.i8, caption: 'Slab Core Drilled - Non Load-Bearing Wall' },
    { img: data.i9, caption: 'Slab Core Drilled - Load-Bearing Wall' },
    { img: data.i10, caption: 'Core Drilling the Epicore MSR Slab' },
    { img: data.i11, caption: 'Epicore MSR Slab Blockouts' },
    { img: data.i12, caption: 'Block-out for Mechanical Chase' },
    { img: data.i13, caption: 'Conduit in Epicore MSR Slabs' },
    { img: data.i14, caption: 'Electrical Conduit in Epicore MSR Slab' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: EPICORE MSR Plumbing Sleeves / Blockouts / Core Drilling" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>EPICORE MSR Plumbing Sleeves / Blockouts / Core Drilling</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>Unlike post-tensioned, pre-stressed/pre-cast, or joist-type floor
            systems, the Epicore MSR Composite Floor System is a very versatile
            floor system allowing the M/E/P subs much more flexibility to sleeve,
            blockout, or core-drill the Epicore MSR slabs for penetrations.</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/shoring-reshoring/">
                Next Page: Shoring / Reshoring
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="/system-overview/epicore-msr-floor/">
                Prev Page: EPICORE MSR Composite Floor
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/01-MEP180_(34283).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i2: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/02-MEP070_(42830).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i3: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/03-_Roofs060_(42971).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i4: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/04-MEP040_(42903).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i5: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/05-MEP290_(43297).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i6: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/06-MEP030_(42902).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i7: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/07-MEP060_(42905).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i8: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/08-MEP230_(41345).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i9: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/09-MEP240_(41364).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i10: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/10-MEP010_(42900).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i11: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/11-MEP190_(34284).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i12: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/12-MEP150_(34251).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i13: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/13-MEP170_(34282).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i14: file(relativePath: { eq: "system-overview/mep-in-slab/gallery/14-MEP200_(34329).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
